import React from "react";
import united_gambian from "../../assets/images/united-gambian.jpg";
import { Container, Row, Col } from "reactstrap";
import { inquirelists } from "../../utils/constant";

const roundLink = {
  color: "#fff",
  backgroundColor: " #cc1b00",
  borderRadius: "9px",
  flex: "0 auto",
  alignSelf: "center",
  alignItems: "center",
  margin: "10px",
  fontSize: "1.2rem",
  fontWeight: "600",
  textDecoration: "none",
  padding: "9px 15px",
  cursor: "pointer",
  display: "inline-block",
};
const Index = () => {
  return (
    <Container className="mb-4">
      <Row className="justify-content-center">
        <Col
          sm="12"
          md={{
            size: 10,
          }}
        >
          <img
            src={united_gambian}
            loading="lazy"
            style={{ width: "100%" }}
            alt="A girl in Gambia with a Full circle learning shirt."
          />

          <p className="fs-s">
            FCL's young change agents come from schools around the world as well
            as from Girls United (GU) Clubs. This GU participant documented a
            project in the Gambia. Learn about all our services and background.
          </p>
          <Row>
            <p>
              An independent study in 2022 measured the relationship between
              humanitarian education systems and resilient identities, based on
              decades of Full-Circle Learning data.{" "}
              <a
                href="https://fullcirclelearningassets.s3.amazonaws.com/Independent+Assessment+at+Year+30+-+Humanitarian+Education+Systems+and+Resilient+Identities+(2023_01_30+04_09_02+UTC).pdf"
                className="inquires"
              >
                Find the summary here.
              </a>
            </p>
          </Row>
          <Row>
            {inquirelists.map((list) => (
              <Col
                sm="4"
                key={list.id}
                style={{ textAlign: "center" }}
                className="inquire_font"
              >
                <a href={list.url} style={roundLink} className="inquires">
                  {list.name}
                </a>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
